import styled from 'styled-components';
import { Box } from 'rebass';

import { StyledButton } from 'common/Button';

export const Wrapper = Box.extend`
  height: auto;
  background-color: ${props => props.theme.colors.white};
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  color: #350922;
  cursor: pointer;
`;

export const HorizontalLine = styled.hr`
  opacity: 0.2;
  border: 1px solid #350922;
  width: 90%;
`;

export const ViewCartButton = StyledButton.extend`
  background-color: #350922;

  &:hover {
    background-color: #1d0111;
  }
`;

export const DeleteIcon = Box.extend`
  background-color: #fff;
  margin-top: 7px;
  margin-left: 4px;
  width: 8px;
  height: 2px;
`;
