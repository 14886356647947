import styled from 'styled-components';

export const Input = styled.input`
  width: 78vw;
  color: ${props => props.theme.colors.black}
  font-size: 16px;
  border-radius: 40px;
  padding: 6px 0 6px 10px;
  background-color: #f8efc9;

  @media (min-width: 32em) {
    width: 40vw;
    font-size: 18px;
  }

  @media (min-width: 64em) {
    width: 400px;
  }

  ::placeholder {
    color: #A38A81;
  }
`;

export const Button = styled.button`
  width: 150px;
  background: #facd00;
  margin-left: 20px;
  border-radius: 40px;
  line-height: normal;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  letter-spacing: 0.05em;
  color: #350922;
  cursor: pointer;

  &:hover {
    background-color: #e2be1b;
  }

  &:focus {
    box-shadow: none;
  }

  @media (min-width: 32em) {
    font-size: 18px;
  }

  @media (min-width: 48em) {
    height: 37px;
  }
`;
