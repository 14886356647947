/* eslint no-sparse-arrays: 0 */
import * as React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { Box } from 'rebass';

import { capitalize } from 'helpers/string';
import { GeorgiaText } from 'common/Text';
import { A } from 'common/A';

const StyledLink = styled(NavLink)`
  text-decoration: none;
  color: #fff;
`;

const handleActiveEvent = (match, location, link) => {
  if (location.pathname === '/' && link === 'home') {
    return true;
  }

  if (!match) {
    return false;
  }

  return true;
};

const StyledLinkNav = ({
  link,
  children,
}: {
  link: string,
  children: React.ReactNode,
}) => (
  <StyledLink
    activeStyle={{
      color: '#FACD00',
    }}
    isActive={(match, location) => handleActiveEvent(match, location, link)}
    to={`/${link}`}
  >
    {children}
  </StyledLink>
);

const LinkNav = ({ link }: { link: string }) => (
  <Box my={[3]}>
    {link === 'blog' ? (
      <A linkTo="https://medium.com/creeptoma">
        <GeorgiaText color="#fff" pl={[4]}>
          {capitalize(link)}
        </GeorgiaText>
      </A>
    ) : (
      <StyledLinkNav link={link}>
        <GeorgiaText pl={[4]}>{capitalize(link)}</GeorgiaText>
      </StyledLinkNav>
    )}
  </Box>
);

export { LinkNav };
