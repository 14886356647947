/**
 * A link to a certain page, an anchor tag
 */

import styled from 'styled-components';

export const A = styled.a.attrs({
  rel: 'noopener noreferrer',
  target: '_blank',
  href: props => props.linkTo,
})`
  text-decoration: none;
`;
