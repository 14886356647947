/* eslint no-sparse-arrays: 0 */
import * as React from 'react';
import { Fixed, Flex, Box, Image } from 'rebass';

import { RobotoText } from 'common/Text';
import { Wrapper } from './styles';
import { cartIcon } from './img';

const MinicartSmall = ({
  onclick,
  totalCreeptoma,
  totalPrice,
}: {
  onclick: Function,
  totalCreeptoma: number,
  totalPrice: number,
}) => (
  <Fixed
    onClick={onclick}
    zIndex={999}
    right={['5px', , '20px', , , '5vw', '10vw', '15vw']}
    top={['3px', 40, 90]}
  >
    <Wrapper w={[280]}>
      <Flex alignItems="center">
        <Box w={1}>
          <Flex alignItems="center">
            <Image src={cartIcon} />
            <RobotoText fontWeight="bold" mr={1}>
              {totalCreeptoma}
            </RobotoText>
            <RobotoText>
              Creeptoma
              {totalCreeptoma > 1 ? 's' : ''}
            </RobotoText>
          </Flex>
        </Box>
        <Box mr={4}>
          <Flex>
            <RobotoText fontWeight="bold" mr={1}>
              {totalPrice.toFixed(4)}
            </RobotoText>
            <RobotoText>ETH</RobotoText>
          </Flex>
        </Box>
      </Flex>
    </Wrapper>
  </Fixed>
);

export default MinicartSmall;
