import * as React from 'react';

import { Consumer } from 'provider';

const withConsumer = (WrappedComponent: React.ComponentType<any>) =>
  /* eslint react/prefer-stateless-function: 0 */
  class WithConsumer extends React.Component<*, *> {
    render() {
      return (
        <Consumer>
          {props => {
            const finalProps = {
              ...props,
              ...this.props,
            };
            return <WrappedComponent {...finalProps} />;
          }}
        </Consumer>
      );
    }
  };

export default withConsumer;
