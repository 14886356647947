/* eslint react/no-unused-state: 0 */
/* eslint no-undef: 0 */
import * as React from 'react';

// import {
//   totalCreeptomaPrice,
//   adoptCreeptomas,
// } from 'blockchain/creeptomaPresale';

import { isPricingPresalePhase } from 'config/config';
import { aquaIcon, firaIcon, aetherIcon, gaiaIcon } from 'img';

export const { Provider, Consumer } = React.createContext();

export type Cart = {
  aqua: number[],
  fira: number[],
  aether: number[],
  gaia: number[],
};

export type PricingCardType = {
  id: number,
  name: string,
  price: number,
  nextPrice: number,
};

class CreeptomaAppProvider extends React.Component<{
  children: React.ReactNode,
}> {
  constructor(props) {
    super(props);
    let accountAddress;

    const isMetaMaskExisted = false;

    // https://github.com/MetaMask/faq/blob/master/DEVELOPERS.md#ear-listening-for-selected-account-changes
    if (isMetaMaskExisted) {
      [accountAddress] = web3.eth.accounts;
      this.accountInterval = setInterval(() => {
        if (web3.eth.accounts[0] !== accountAddress) {
          [accountAddress] = web3.eth.accounts;
          this.setState({
            accountAddress,
          });
        }
      }, 100);
    }

    this.state = {
      accountAddress,
      referrerAddress: 0,
      isMetaMaskExisted,
      isPricingPresalePhase: isPricingPresalePhase(),

      language: undefined,
      content: undefined,
      chooseLanguage: this.chooseLanguage,

      pricingCardsInfo: [
        {
          id: 1,
          name: 'Aqua',
          src: aquaIcon,
        },
        {
          id: 2,
          name: 'Fira',
          src: firaIcon,
        },
        {
          id: 3,
          name: 'Aether',
          src: aetherIcon,
        },
        {
          id: 4,
          name: 'Gaia',
          src: gaiaIcon,
        },
      ],

      totalPrice: 0,

      cart: {
        aqua: [],
        fira: [],
        aether: [],
        gaia: [],
      },
      addToCart: this.addToCart,
      checkout: this.checkout,
      removeFromCart: this.removeFromCart,
      setReferrerAddress: this.setReferrerAddress,
    };
  }

  componentDidMount = () => {
    Promise.all([this.getContent()]);
  };

  componentWillUnmount = () => {
    clearInterval(this.accountInterval);
  };

  // getCreeptomaPricing = async (
  //   aquaQuantity,
  //   firaQuantity,
  //   aetherQuantity,
  //   gaiaQuantity
  // ) => {
  //   const data = await totalCreeptomaPrice(
  //     aquaQuantity,
  //     firaQuantity,
  //     aetherQuantity,
  //     gaiaQuantity
  //   ).then(res => {
  //     Object.keys(res).forEach(key => {
  //       res[key] = +res[key].toString();
  //     });

  //     return res;
  //   });
  //   return data;
  // };

  setReferrerAddress = (referrerAddress: string) => {
    this.setState({
      referrerAddress,
    });
  };

  getContent = async () => {
    const language = localStorage.getItem('language') || 'en';
    const content = await this.loadContent(language);

    this.setState({
      content,
      language,
    });
  };

  loadContent = async (language: string) => {
    const { default: getContentFunc } = await import(`lang/${language}`);
    const content = await getContentFunc();
    return content;
  };

  checkout = async isGuaranteed => {
    const [
      aquaQuantity,
      firaQuantity,
      aetherQuantity,
      gaiaQuantity,
    ] = Object.keys(this.state.cart).map(key => this.state.cart[key].length);

    const { accountAddress, referrerAddress, totalPrice } = this.state;

    try {
      const result = await adoptCreeptomas(
        aquaQuantity,
        firaQuantity,
        aetherQuantity,
        gaiaQuantity,
        accountAddress,
        referrerAddress,
        totalPrice,
        isGuaranteed
      );

      this.initPricing(0, 0, 0, 0);
      this.setState({
        totalPrice: 0,
        cart: {
          aqua: [],
          fira: [],
          aether: [],
          gaia: [],
        },
      });

      return result;
    } catch (error) {
      return 0;
    }
  };
  initPricing = async (
    aquaQuantity = 0,
    firaQuantity = 0,
    aetherQuantity = 0,
    gaiaQuantity = 0
  ) => {
    const data = await this.getCreeptomaPricing(
      aquaQuantity,
      firaQuantity,
      aetherQuantity,
      gaiaQuantity
    );

    const {
      totalPrice,
      aetherCurrentPrice,
      aetherPriceIncrement,
      aquaCurrentPrice,
      aquaPriceIncrement,
      firaCurrentPrice,
      firaPriceIncrement,
      gaiaCurrentPrice,
      gaiaPriceIncrement,
    } = data;

    const updatedPricingCardsInfo = [
      {
        id: 1,
        name: 'Aqua',
        src: aquaIcon,
        price: aquaCurrentPrice.toFixed(4),
        nextPrice: +Number.parseFloat(
          aquaCurrentPrice + aquaPriceIncrement
        ).toFixed(4),
      },
      {
        id: 2,
        name: 'Fira',
        src: firaIcon,
        price: firaCurrentPrice.toFixed(4),
        nextPrice: +Number.parseFloat(
          firaCurrentPrice + firaPriceIncrement
        ).toFixed(4),
      },
      {
        id: 3,
        name: 'Aether',
        src: aetherIcon,
        price: aetherCurrentPrice.toFixed(4),
        nextPrice: +Number.parseFloat(
          aetherCurrentPrice + aetherPriceIncrement
        ).toFixed(4),
      },
      {
        id: 4,
        name: 'Gaia',
        src: gaiaIcon,
        price: gaiaCurrentPrice.toFixed(4),
        nextPrice: +Number.parseFloat(
          gaiaCurrentPrice + gaiaPriceIncrement
        ).toFixed(4),
      },
    ];

    this.setState({
      totalPrice,
      pricingCardsInfo: updatedPricingCardsInfo,
    });
  };

  addToCart = async creeptoma => {
    const { name, price } = creeptoma;
    const creeptomaName = name.toLowerCase();
    if (this.state.cart[creeptomaName].length === 3) {
      // eslint-disable-next-line
      alert(`No bro, max 3 ${creeptomaName} creeptoma already`);
      return;
    }

    const [
      aquaQuantity,
      firaQuantity,
      aetherQuantity,
      gaiaQuantity,
    ] = Object.keys(this.state.cart).map(key => {
      if (key === name.toLowerCase()) {
        return this.state.cart[key].length + 1;
      }
      return this.state.cart[key].length;
    });

    if (name === 'Aqua') {
      const {
        totalPrice,
        aquaCurrentPrice,
        aquaPriceIncrement,
      } = await this.getCreeptomaPricing(
        aquaQuantity,
        firaQuantity,
        aetherQuantity,
        gaiaQuantity
      );

      this.setState({
        totalPrice,
        pricingCardsInfo: this.state.pricingCardsInfo.map(item => {
          if (item.name !== name) {
            return item;
          }
          return {
            ...item,
            price: aquaCurrentPrice.toFixed(4),
            nextPrice: +Number.parseFloat(
              aquaCurrentPrice + aquaPriceIncrement
            ).toFixed(4),
          };
        }),
      });
    } else if (name === 'Fira') {
      const {
        totalPrice,
        firaCurrentPrice,
        firaPriceIncrement,
      } = await this.getCreeptomaPricing(
        aquaQuantity,
        firaQuantity,
        aetherQuantity,
        gaiaQuantity
      );

      this.setState({
        totalPrice,
        pricingCardsInfo: this.state.pricingCardsInfo.map(item => {
          if (item.name !== name) {
            return item;
          }
          return {
            ...item,
            price: firaCurrentPrice.toFixed(4),
            nextPrice: +Number.parseFloat(
              firaCurrentPrice + firaPriceIncrement
            ).toFixed(4),
          };
        }),
      });
    } else if (name === 'Aether') {
      const {
        totalPrice,
        aetherCurrentPrice,
        aetherPriceIncrement,
      } = await this.getCreeptomaPricing(
        aquaQuantity,
        firaQuantity,
        aetherQuantity,
        gaiaQuantity
      );

      this.setState({
        totalPrice,
        pricingCardsInfo: this.state.pricingCardsInfo.map(item => {
          if (item.name !== name) {
            return item;
          }
          return {
            ...item,
            price: aetherCurrentPrice.toFixed(4),
            nextPrice: +Number.parseFloat(
              aetherCurrentPrice + aetherPriceIncrement
            ).toFixed(4),
          };
        }),
      });
    } else {
      const {
        totalPrice,
        gaiaCurrentPrice,
        gaiaPriceIncrement,
      } = await this.getCreeptomaPricing(
        aquaQuantity,
        firaQuantity,
        aetherQuantity,
        gaiaQuantity
      );

      this.setState({
        totalPrice,
        pricingCardsInfo: this.state.pricingCardsInfo.map(item => {
          if (item.name !== name) {
            return item;
          }
          return {
            ...item,
            price: gaiaCurrentPrice.toFixed(4),
            nextPrice: +Number.parseFloat(
              gaiaCurrentPrice + gaiaPriceIncrement
            ).toFixed(4),
          };
        }),
      });
    }

    this.setState({
      cart: {
        ...this.state.cart,
        [creeptomaName]: this.state.cart[creeptomaName].concat(+price),
      },
    });
  };

  removeFromCart = async creeptomaName => {
    const [
      aquaQuantity,
      firaQuantity,
      aetherQuantity,
      gaiaQuantity,
    ] = Object.keys(this.state.cart).map(key => {
      if (key === creeptomaName.toLowerCase()) {
        return 0;
      }
      return this.state.cart[key].length;
    });

    this.initPricing(aquaQuantity, firaQuantity, aetherQuantity, gaiaQuantity);

    this.setState({
      cart: {
        ...this.state.cart,
        [creeptomaName]: [],
      },
    });
  };

  chooseLanguage = language => {
    localStorage.setItem('language', language);
    this.setState({ language }, this.getContent);
  };

  render() {
    const { children } = this.props;
    const { content } = this.state;
    return (
      <Provider value={this.state}>
        {content ? children : 'Loading...'}
      </Provider>
    );
  }
}

export default CreeptomaAppProvider;
