import * as React from 'react';
import { IntlProvider } from 'react-intl';

import { Consumer } from 'provider';

const LanguageManager = ({ children }: { children: React.ReactNode }) => (
  <Consumer>
    {({ language, content }) => (
      <IntlProvider locale={language} key={language} messages={content}>
        {children}
      </IntlProvider>
    )}
  </Consumer>
);

export default LanguageManager;
