import styled from 'styled-components';

// https://css-tricks.com/couple-takes-sticky-footer/ - Grid layout
export const Wrapper = styled.div`
  display: grid;
  min-height: 100vh;
  max-width: 100vw;
  grid-template-rows: 1fr auto;
  -webkit-overflow-scrolling: touch;
`;

export const Footer = styled.div`
  max-width: 100vw;
  grid-row-start: 2;
  grid-row-end: 3;
`;
