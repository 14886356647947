import * as React from 'react';
import { withRouter } from 'react-router';

class ScrollManager extends React.Component<{
  location: any,
  children: React.ReactNode,
}> {
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return this.props.children;
  }
}

export default withRouter(ScrollManager);
