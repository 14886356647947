import * as React from 'react';
import styled from 'styled-components';
import compose from 'helpers/compose';
import withConsumer from 'helpers/withConsumer';
import { withRouter } from 'react-router-dom';
import { Box } from 'rebass';

import Logo from './Logo';
import { navList } from './feedData';
import { LinkNav } from './Link';

const FixTopNavbar = styled.div`
  display: flex;
  justify-content: center;
  font-weight: light;
  letter-spacing: 2px;
  position: fixed;
  width: 100vw;
  z-index: 999;
  padding: 8px 0 8px 0;

  background-color: ${props =>
    props.didscroll ? 'rgba(26, 3, 22, 0.96)' : 'transparent'};

  transition: background-color 0.5s ease-in-out 0s;
`;

const NavBarWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 1170px;
`;

const NavBarTextWrapper = styled.div`
  display: flex;
  font-size: 18px;
  align-items: center;
`;

const LanguageWrapper = styled.div`
  display: inline-block;
  padding-left: 32px;
`;

const Divider = styled.div`
  display: inline-block;
  opacity: 0.5;
  color: #fff;
  padding: 0 10px;
`;

const LanguageButton = styled.a`
  opacity: 0.7;
  color: #fff;
  opacity: ${props => (props.language ? '1' : '')};
  font-weight: ${props => (props.language ? 'bold' : '')};
  font-family: Georgia, sans-serif;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
`;

const PlayNowButton = styled.button`
  background: linear-gradient(180deg, rgba(250,203,0,1) 0%, rgba(255,153,0,1) 100%);
  border: none;
  border-radius: 14px;
  color: black;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  font-weight: 800;
  margin: 4px 2px 4px 32px;
  cursor: pointer;

  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  transition-duration: 0.3s;

  &:hover {
    transform: scale(1.2);
    transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
  }
`;

class NavBar extends React.Component<*, *> {
  state = {
    didScroll: false,
  };
  componentDidMount() {
    this.checkScroll = requestAnimationFrame(this.checkScrollFunc);
  }

  componentWillUnmount() {
    cancelAnimationFrame(this.checkScroll);
  }

  checkScrollFunc = () => {
    const { scrollTop } = document.documentElement;
    const height = window.innerHeight;
    const {
      location: { pathname },
    } = this.props;
    if (
      scrollTop > height ||
      /article|nometamask|cart|playground/.test(pathname)
    ) {
      this.setState(() => ({
        didScroll: true,
      }));
    } else {
      this.setState(() => ({
        didScroll: false,
      }));
    }
    this.checkScroll = requestAnimationFrame(this.checkScrollFunc);
  };

  render() {
    const { didScroll } = this.state;
    const { language, chooseLanguage } = this.props;
    return (
      <FixTopNavbar didscroll={didScroll ? 1 : 0}>
        <NavBarWrapper>
          <Logo />
          <NavBarTextWrapper>
            {navList.map(({ link, id }) => (
              <LinkNav key={id} link={link} />
            ))}

            <PlayNowButton onClick={() => {
              window.open('https://game.chestorchess.com', '_blank')
            }}>Play Now</PlayNowButton>

            <Box my={[3]}>
              <LanguageWrapper>
                <LanguageButton
                  language={language === 'en'}
                  onClick={() => chooseLanguage('en')}
                >
                  EN
                </LanguageButton>
                <Divider> | </Divider>
                <LanguageButton
                  language={language === 'ja'}
                  onClick={() => chooseLanguage('ja')}
                >
                  JA
                </LanguageButton>
              </LanguageWrapper>
            </Box>
          </NavBarTextWrapper>
        </NavBarWrapper>
      </FixTopNavbar>
    );
  }
}

export default compose(
  withConsumer,
  withRouter
)(NavBar);
