import characterIconOriginal from './character_icon_original.png';
import characterIconPlaceholder from './character_icon_placeholder.png';

import creeptomaIconOriginal from './creeptoma_icon_original.png';
import creeptomaIconPlaceholder from './creeptoma_icon_placeholder.png';

import creeptomaLogoOriginal from './creeptoma_logo_original.png';
import creeptomaLogoPlaceholder from './creeptoma_logo_placeholder.png';

import gameplayIconOriginal from './gameplay_icon_original.png';
import gameplayIconPlaceholder from './gameplay_icon_placeholder.png';

import logoOriginal from './logo_original.svg';
import logoPlaceholder from './logo_placeholder.png';

import worldIconOriginal from './world_icon_original.png';
import worldIconPlaceholder from './world_icon_placeholder.png';

import discordIcon from './discord.svg';
import facebookIcon from './facebook.svg';
import instagramIcon from './instagram.svg';
import mediumIcon from './medium.svg';
import redditIcon from './reddit.svg';
import telegramIcon from './telegram.svg';
import twitterIcon from './twitter.svg';

import aetherIcon from './aether.png';
import aquaIcon from './aqua.png';
import firaIcon from './fira.png';
import gaiaIcon from './gaia.png';

import darkIcon from './dark.png';
import lightIcon from './light.png';

import diamondImage from './diamond.png';
import farmImage from './farm.png';

import islandBannerOriginal from './island_banner_original.png';
import islandBannerPlaceholder from './island_banner_placeholder.jpg';

import footerBackground from './footerBackground.jpg';

import element from './element.png';

import creeptomaPoster from './creeptomaPoster.jpg';

import mix from './mix.png';

import firaIsland from './firaIsland.png';
import aquaIsland from './aquaIsland.png';
import battle from './battle.png';
import breeding from './breeding.png';
import marketplace from './marketplace.png';
import quest from './quest.png';

import floatingIsland from './floatingIsland.png';
import creeptomaWallpaper from './creeptomaWallpaper.png';
import islandHomeFooterCard from './islandHomeFooterCard.png';
import creeptomaHomeWallpaper from './creeptomaHomeWallpaper.png';
import islandWallpaper from './islandWallpaper.png';

import pricingWallpaper from './pricingWallpaper.png';

import backgroundLegendary from './backgroundLegendary.png';

export {
  aetherIcon,
  aquaIcon,
  aquaIsland,
  battle,
  breeding,
  characterIconOriginal,
  characterIconPlaceholder,
  creeptomaHomeWallpaper,
  creeptomaIconOriginal,
  creeptomaIconPlaceholder,
  creeptomaLogoOriginal,
  creeptomaLogoPlaceholder,
  creeptomaPoster,
  creeptomaWallpaper,
  darkIcon,
  diamondImage,
  discordIcon,
  element,
  facebookIcon,
  farmImage,
  firaIcon,
  firaIsland,
  floatingIsland,
  footerBackground,
  gaiaIcon,
  gameplayIconOriginal,
  gameplayIconPlaceholder,
  instagramIcon,
  islandBannerOriginal,
  islandBannerPlaceholder,
  islandHomeFooterCard,
  islandWallpaper,
  lightIcon,
  logoOriginal,
  logoPlaceholder,
  marketplace,
  mediumIcon,
  mix,
  pricingWallpaper,
  quest,
  redditIcon,
  telegramIcon,
  twitterIcon,
  worldIconOriginal,
  worldIconPlaceholder,
  backgroundLegendary,
};
