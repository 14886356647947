// @flow
// Default theme from Rebass - put it here as reference or to override later if necessary

export const theme = {
  breakpoints: ['32em', '48em', '64em', '80em', '90em', '97.5em', '110.5em'],
  // [512, 768, 1024, 1280, 1440, 1560, 1768] - px
  space: [0, 4, 8, 16, 32, 64, 128],
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 72, 96],
  fontWeights: {
    normal: 400,
    bold: 700,
  },
  fonts: {
    '0': 'system-ui, sans-serif',
    sans: 'system-ui, sans-serif',
    mono: '"SF Mono", "Roboto Mono", Menlo, monospace',
  },
  colors: {
    black: '#000',
    blue: '#0067ee',
    cyan: '#00deee',
    dark: 'rgba(0, 0, 0, 0.75)',
    darken: [
      'rgba(0, 0, 0, 0.125)',
      'rgba(0, 0, 0, 0.25)',
      'rgba(0, 0, 0, 0.5)',
      'rgba(0, 0, 0, 0.75)',
    ],
    fuschia: '#ee00de',
    gray: '#eee',
    green: '#00ee10',
    indigo: '#1000ee',
    lime: '#67ee00',
    orange: '#ee8700',
    pink: '#ee0067',
    red: '#ee1000',
    teal: '#00ee87',
    violet: '#8700ee',
    white: '#fff',
    yellow: '#deee00',

    // Custom global colors
    backgroundColor: '#390A21',
    cardColor: '#F8EFC9',
    navbarColor: 'rgba(26,3,22,0.96)',
    themeYellowColor: '#FACD00',

    // Homepage colors
    homeBackCardColor: '#DDC664',
    homeFooterColor: '#240316',
    homeFrontCardColor: '#F8EFC9',
    homeTimelineColor: '#FACD00',

    // World colors
    worldBackgroundColor: '#230317',
    worldBannerColor: '#390A21',
    worldCerberiaColor: '#DDC664',
    worldEoniaColor: '#C0CDB8',
    worldEtheriaColor: '#B56240',
    worldFooterColor: '#13010C',
    worldLeviaColor: '#67ADD9',
    worldNymphiaColor: '#68D872',
    worldSunColor: '#FACD00',

    // Pricing color
    pricingBannerBackgroundColor: '#240316',
    pricingBannerInfoBackgroundColor: '#F8EFC9',
    pricingBannerInfoTextColor: '#350922',
    pricingCardBackgroundColor: '#F8EFC9',
    pricingCardRectBackgroundColor: '#E2DAB8',
    pricingFooterBackgroundColor: '#240316',
    pricingFooterButtonColor: '#FACD00',
    pricingFooterEmailPlaceholderColor: '#A38A81',
    pricingSpecialBackgroundColor: '#FACD00',
    pricingSpecialCircleColor: '#D3A608',
    pricingSpecialTextColor: '#350922',
    pricingSubscribeBackgroundColor: '#360922',

    // Cart color
    cartBannerBackgroundColor: '#F5CD00',
    cartBannerTextColor: '#350922',
    cartTextBackgroundColor: '#230417',

    // Footer color
    footerBackgroundColor: '#13010C',
    footerSocialIconBackgroundColor: '#4E404A',

    // Character color
    characterRectBackgroundColor: '#F8EFC9',
    characterCircleBackgroundColor: '#230417',
  },
  radii: [0, 2, 4],
  shadows: [
    'none',
    'inset 0 0 0 1px #eee',
    'inset 0 0 0 1px #eee, 0 0 4px #eee',
  ],
};

export type RebassCustomTheme = {
  breakpoints: string[],
  space: number[],
  fontSizes: number[],
  fontWeights: {
    normal: number,
    bold: number,
  },
  fonts: {
    '0': string,
    sans: string,
    mono: string,
  },
  colors: {
    black: string,
    blue: string,
    cyan: string,
    dark: string,
    darken: string[],
    fuschia: string,
    gray: string,
    green: string,
    indigo: string,
    lime: string,
    orange: string,
    pink: string,
    red: string,
    teal: string,
    violet: string,
    white: string,
    yellow: string,

    backgroundColor: string,
    cardColor: string,
    themeYellowColor: string,
    navBarColor: string,

    homeBackCardColor: string,
    homeFooterColor: string,
    homeFrontCardColor: string,
    homeTimelineColor: string,

    worldBackgroundColor: string,
    worldBannerColor: string,
    worldCerberiaColor: string,
    worldEoniaColor: string,
    worldEtheriaColor: string,
    worldFooterColor: string,
    worldLeviaColor: string,
    worldNymphiaColor: string,
    worldSunColor: string,

    pricingBannerBackgroundColor: string,
    pricingBannerInfoBackgroundColor: string,
    pricingBannerInfoTextColor: string,
    pricingCardBackgroundColor: string,
    pricingCardRectBackgroundColor: string,
    pricingFooterBackgroundColor: string,
    pricingSpecialBackgroundColor: string,
    pricingSpecialCircleColor: string,
    pricingSpecialTextColor: string,

    cartBannerBackgroundColor: string,
    cartBannerTextColor: string,
    cartTextBackgroundColor: string,

    characterRectBackgroundColor: string,
    characterCircleBackgroundColor: string,

    footerBackgroundColor: string,
    footerSocialIconBackgroundColor: string,
  },
  radii: number[],
  shadows: string[],
};
