/* eslint no-sparse-arrays: 0 */
import * as React from 'react';
import ProgressiveImage from 'react-progressive-image';

import { Link } from 'react-router-dom';
import { Image, Box } from 'rebass';

import { logoOriginal, logoPlaceholder } from 'img';

const Logo = () => (
  <Box>
    <Link href="home" to="/">
      <ProgressiveImage src={logoOriginal} placeholder={logoPlaceholder}>
        {src => <Image alt="Creeptoma navbar logo" w={[136]} src={src} />}
      </ProgressiveImage>
    </Link>
  </Box>
);

export default Logo;
