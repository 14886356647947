import * as React from 'react';
import styled from 'styled-components';
import { Circle } from 'common/Shape';

export const StyledButton = styled.button`
  background: #facd00;
  font-family: Georgia;
  font-weight: bold;
  width: 180px;
  height: 50px;
  border-radius: 40px;
  outline: none;
  padding: 15px;
  line-height: normal;
  font-size: 18px;
  text-align: center;
  letter-spacing: 0.05em;
  cursor: pointer;
  color: #350922;
  border: 0;

  &:disabled {
    background: #ccc;
    cursor: not-allowed;
  }

  &:hover {
    background-color: #e2be1b;
  }
`;

const StyledButtonText = styled.span`
  font-family: Georgia;
  line-height: normal;
  font-size: 18px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #350922;
  font-weight: bold;
`;

export const CreeptomaButton = ({
  children,
}: {
  children: React.ReactNode,
}) => (
  <StyledButton>
    <StyledButtonText>{children}</StyledButtonText>
  </StyledButton>
);

export const CloseButtonIcon = Circle.extend`
  z-index: 999;
  width: 30px;
  height: 30px;
  position: absolute;
  cursor: pointer;
  background-color: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  color: #350922;
  font-size: 16px;
  padding: 7px 10px 10px 10px;

  top: ${props => (props.top ? props.top : '-15px')}
  right: ${props => (props.right ? props.right : '-15px')}
`;

const CloseButtonWrapper = styled.div`
  position: relative;
`;

export const CloseButton = ({
  onclick,
  top,
  right,
}: {
  onclick: Function,
  top: string,
  right: string,
}) => (
  <CloseButtonWrapper>
    <CloseButtonIcon top={top} right={right} onClick={onclick}>
      X
    </CloseButtonIcon>
  </CloseButtonWrapper>
);
