import styled from 'styled-components';
import * as React from 'react';
import { withRouter } from 'react-router-dom';

const BackgroundWrapper = styled.div`
  position: absolute;
  min-height: 100vh;
  overflow-x: hidden;
  left: 0;
  right: 0;

  z-index: -7;

  background: ${props => props.isWhite};
`;

const pagesColorsMap = {
  creeptoma: true,
  pricing: true,
};

const Background = ({ location, children }) => {
  const { pathname } = location;
  const pageName = pathname.substring(1);
  const isWhite = pagesColorsMap[pageName] ? '#fff' : '#f9f3f6';
  return <BackgroundWrapper isWhite={isWhite}>{children}</BackgroundWrapper>;
};

export default withRouter(Background);
