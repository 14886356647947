// @flow
import * as React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { render } from 'react-dom';
import { Provider as RebassProvider } from 'rebass';

import 'pixi.js';
import 'pixi-spine';

import 'flexibility'; // flexbox
import 'vminpoly'; // vm,vh,vmin,vmax
import 'isomorphic-fetch';
import LanguageManager from 'managers/Language';
import ScrollManager from 'managers/Scroll';
import { isMaintenanceMode } from 'config/config';

import './globalStyle';
import Routes from './routes';
import { theme } from './theme';

import * as serviceWorker from './serviceWorker';

import CreeptomaAppProvider from './provider';

const rootElement = document.getElementById('root');

const App = () => (
  <CreeptomaAppProvider>
    <LanguageManager>
      <RebassProvider theme={theme}>
        <BrowserRouter>
          <ScrollManager>
            <Routes maintenanceMode={isMaintenanceMode()} />
          </ScrollManager>
        </BrowserRouter>
      </RebassProvider>
    </LanguageManager>
  </CreeptomaAppProvider>
);

// $FlowFixMe
render(<App />, rootElement);

// $FlowFixMe
if (module.hot) {
  module.hot.accept(App, () => {
    // $FlowFixMe
    render(<App />, rootElement);
  });
}

serviceWorker.unregister();
