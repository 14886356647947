/* eslint no-sparse-arrays: 0 */
import React from 'react';
import { Fixed, Flex } from 'rebass';

import { SocialIcon } from 'common/SocialIcon';
import { SocialMediaInfoItem } from '../../feedData';

const SocialMediaInfo = ({
  socialMediaInfo,
}: {
  socialMediaInfo: SocialMediaInfoItem[],
}) => (
  <Fixed zIndex={999} bottom={[20]} right={[10]}>
    <Flex w={[90, , 'auto']} justifyContent="space-between">
      {socialMediaInfo.map(
        ({ id, name, socialColor, src, link, hoverColor }) => (
          <SocialIcon
            link={link}
            key={id}
            name={name}
            hoverColor={hoverColor}
            socialColor={socialColor}
            src={src}
          />
        )
      )}
    </Flex>
  </Fixed>
);

export default SocialMediaInfo;
