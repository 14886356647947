/* eslint no-sparse-arrays: 0 */
import * as React from 'react';
import styled from 'styled-components';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { footerBackground } from 'img';

import withConsumer from 'helpers/withConsumer';
import { Input, Button } from './styles';

const ReferLinkWrapper = styled.div`
  height: 275px;
  width: 100vw;
  display: flex;
  flex-direction: column;
  font-family: Georgia;
  align-items: center;
  justify-content: center;
  background-image: url(${props => props.src});
  background-size: 100vw 300px;
`;

const ReferLinkText = styled.p`
  line-height: normal;
  font-size: 24px;
  text-align: center;
  font-weight: lighter;
  color: #ffffff;
`;

const ReferLinkShare = styled.div`
  display: flex;
  justify-content: center;
  align-items: baseline;
`;

class ReferLink extends React.Component<{
  accountAddress: string,
}> {
  handleCopy = () => {
    // eslint-disable-next-line
    alert('You just copy refer link to clipboard');
  };

  handleFocus = e => {
    e.target.select();
  };
  render() {
    const referLink = `${window.location.href}?referrer=${
      this.props.accountAddress
    }`;
    return this.props.accountAddress ? (
      <ReferLinkWrapper src={footerBackground}>
        <ReferLinkText>
          &quot;Invite your friend and get free Creeptoma&rdquo;
        </ReferLinkText>
        <ReferLinkShare>
          <Input onFocus={this.handleFocus} my={3} readOnly value={referLink} />
          <CopyToClipboard text={referLink} onCopy={this.handleCopy}>
            <Button onClick={this.handleClick} ml={3}>
              COPY
            </Button>
          </CopyToClipboard>
        </ReferLinkShare>
      </ReferLinkWrapper>
    ) : null;
  }
}

export default withConsumer(ReferLink);
