/* eslint no-sparse-arrays: 0 */
import * as React from 'react';
import ProgressiveImage from 'react-progressive-image';
import { Box, Flex, Image } from 'rebass';

import ReferLink from 'common/ReferLink';
import { LaBelleText, RobotoText } from 'common/Text';
import { SocialIcon } from 'common/SocialIcon';
import { logoOriginal, logoPlaceholder } from 'img';
import { FooterWrapper } from './styles';

import { socialIcons } from './feedData';

const Footer = (props: any) => {
  const {
    location: { pathname },
  } = props;

  const renderReferLink = pathname === '/cart';
  return (
    <React.Fragment>
      {renderReferLink ? (
        <ReferLink color="#fff" bg="#240316" role="contentinfo" />
      ) : null}
      <FooterWrapper w="100vw">
        <Flex
          justifyContent={['space-around', , 'auto', , 'center']}
          flexWrap="wrap"
          py={4}
        >
          <Box w={['100vw', , '50vw', 'auto']}>
            <Flex flexDirection="column" alignItems="center">
              <ProgressiveImage
                src={logoOriginal}
                placeholder={logoPlaceholder}
              >
                {src => (
                  <Image alt="Creeptoma footer logo" w={[270]} src={src} />
                )}
              </ProgressiveImage>
              <LaBelleText my={3} f={[1, 2]}>
                &copy; 2018 Creeptoma Inc. All rights reserved.
              </LaBelleText>
            </Flex>
          </Box>
          <Box
            mb={4}
            w={['100vw', , '35vw', 'auto']}
            ml={[, , , , 100]}
            mr={[, , , , 150]}
          >
            <Flex w={['100vw', , 'auto']} justifyContent="space-around">
              <Box>
                <RobotoText mb={3}>Email Us</RobotoText>
                <RobotoText>FAQs</RobotoText>
              </Box>
              <Box ml={[, , 4]}>
                <RobotoText mb={3}>About</RobotoText>
                <RobotoText>Latest Updates</RobotoText>
              </Box>
            </Flex>
          </Box>
          <Box w={['100vw', , 'auto']}>
            <Flex justifyContent={['space-evenly', , 'center']}>
              {socialIcons.map(({ id, name, src, hoverColor, link }) => (
                <SocialIcon
                  key={id}
                  name={name}
                  src={src}
                  hoverColor={hoverColor}
                  link={link}
                />
              ))}
            </Flex>
          </Box>
        </Flex>
      </FooterWrapper>
    </React.Fragment>
  );
};

export default Footer;
