import * as React from 'react';
import { Box, Flex, Text } from 'rebass';

import { capitalize } from 'helpers/string';

import { Circle } from 'common/Shape';
import { DeleteIcon } from './styles';

const MinicartBigRow = ({
  name,
  removeFromCart,
  total,
  length,
}: {
  name: string,
  removeFromCart: Function,
  total: number,
  length: number,
}) => {
  if (!length) {
    return null;
  }
  return (
    <Box mt={2} w={1}>
      <Flex>
        <Box w={60}>
          <Text ml={3} fontWeight="bold">
            {capitalize(name)}
          </Text>
        </Box>
        <Flex w={30} ml={65} alignItems="center">
          <Text fontWeight="bold" f={2}>
            {length}
          </Text>
          <Text color="#745566"> /3</Text>
        </Flex>
        <Box w={77} ml={33}>
          <Flex justifyContent="space-between">
            <Text fontWeight="bold">{total}</Text>
            <Circle onClick={() => removeFromCart(name)} size="16" bg="#350922">
              <DeleteIcon />
            </Circle>
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
};

export default MinicartBigRow;
