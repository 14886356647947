import * as React from 'react';

import SocialMediaInfo from './components/SocialMediaInfo';

import { socialMediaInfo } from './feedData';

class SocialMediaContainer extends React.Component {
  state = {
    socialMediaInfo,
  };
  render() {
    return <SocialMediaInfo socialMediaInfo={this.state.socialMediaInfo} />;
  }
}

export default SocialMediaContainer;
