import styled from 'styled-components';

export const Circle = styled.div`
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  background-image: url(${props => props.src});
  background-size: contain;
  background-color: ${props => (props.bg ? props.bg : 'transparent')}
  border-radius: 50%;
  margin-top: ${props => (props.mt ? props.mt : 'auto')};
  margin-bottom: ${props => (props.mb ? props.mb : 'auto')};
  cursor: ${props => props.cursor};
`;
