export const isMaintenanceMode = () =>
  process.env.REACT_APP_MAINTENANCE_MODE === 'enable';

export const getBlockchainName = () => process.env.REACT_APP_BLOCKCHAIN_NAME;

export const isPricingPresalePhase = () =>
  process.env.REACT_APP_PRICING_PRESALE === 'enable';

export const isLocalBlockchain = () => {
  const provider = getBlockchainName();
  return provider === undefined || provider === null || provider === 'local';
};

export const getContractAddress = () => {
  const provider = getBlockchainName();
  if (provider === 'rinkeby') {
    return process.env.REACT_APP_RINKEBY_ADDR;
  } else if (provider === 'ropsten') {
    return process.env.REACT_APP_ROPSTEN_ADDR;
  }

  throw new Error(`Blockchain ${getBlockchainName()} undefined`);
};

export const getWeb3ProviderAddress = () => {
  const provider = getBlockchainName();
  if (isLocalBlockchain()) {
    return process.env.REACT_APP_LOCAL_WEB3_PROVIDER;
  } else if (provider === 'mainnet') {
    return process.env.REACT_APP_INFURA_MAINNET;
  } else if (provider === 'ropsten') {
    return process.env.REACT_APP_INFURA_ROPSTEN;
  } else if (provider === 'rinkeby') {
    return process.env.REACT_APP_INFURA_RINKEBY;
  } else if (provider === 'kovan') {
    return process.env.REACT_APP_INFURA_KOVAN;
  }

  throw new Error(`Blockchain ${getBlockchainName()} undefined`);
};
