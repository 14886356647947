import * as React from 'react';
import styled from 'styled-components';
import Spinner from 'react-spinkit';

const SpinnerWrapper = styled.div`
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
`;

const Loading = () => (
  <SpinnerWrapper alignItems="center" justifyContent="center">
    <Spinner color="#FACD00" name="circle" />
  </SpinnerWrapper>
);

export default Loading;
