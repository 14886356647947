const navListMobile = [
  'home',
  'creeptoma',
  'island',
  // 'characters',
  'gameplay',
  'pricing',
  'blog',
].map((link, index) => ({
  id: index,
  link,
}));

const [, ...navList] = navListMobile;

export { navList, navListMobile };
