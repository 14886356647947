import styled from 'styled-components';
import { Text } from 'rebass';

export const LooseTextOneHalf = Text.extend`
  max-width: 100vw;
  line-height: 1.5;
`;
export const LooseTextTwentyEight = styled.div`
  max-width: 100vw;
  line-height: 28px;
`;

export const CenterText = Text.extend`
  text-align: center;
`;

export const LaBelleText = Text.extend`
  letter-spacing: 1.5px;
  font-family: Georgia, sans-serif;
  max-width: ${props => (props.maxwidth ? `${props.maxwidth}px` : 'initial')};
`;

export const RobotoText = Text.extend`
  font-family: Georgia, sans-serif;
  max-width: ${props => (props.maxwidth ? `${props.maxwidth}px` : 'initial')};
`;

export const GeorgiaText = Text.extend`
  font-family: Georgia, sans-serif;
`;

export const WhiteText = Text.extend`
  color: ${props => props.theme.colors.white};
`;

export const BlackText = Text.extend`
  color: ${props => props.theme.colors.black};
`;

export const H1 = styled.h1`
  font-size: inherit;
  margin: 0 auto;
`;
