import {
  discordIcon,
  facebookIcon,
  instagramIcon,
  mediumIcon,
  redditIcon,
  telegramIcon,
  twitterIcon,
} from 'img';

export const socialIcons = [
  {
    id: 1,
    name: 'Telegram',
    src: telegramIcon,
    hoverColor: '#0088cc',
  },
  {
    id: 2,
    name: 'Discord',
    src: discordIcon,
    hoverColor: '#7289da',
  },
  {
    id: 3,
    name: 'Medium',
    src: mediumIcon,
    hoverColor: '#00ab6c',
    link: 'https://medium.com/creeptoma',
  },
  {
    id: 4,
    name: 'Reddit',
    src: redditIcon,
    hoverColor: '#ff4500',
  },
  {
    id: 5,
    name: 'Twitter',
    src: twitterIcon,
    hoverColor: '#1da1f2',
  },
  {
    id: 6,
    name: 'Instagram',
    src: instagramIcon,
    hoverColor: '#e1306c',
  },
  {
    id: 7,
    name: 'Facebook',
    src: facebookIcon,
    hoverColor: '#3b5998',
  },
];
