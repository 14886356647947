/* eslint no-sparse-arrays: 0 */
import * as React from 'react';
import { Box, Fixed, Flex, Text } from 'rebass';

import { CloseButton } from 'common/Button';
import type { Cart } from 'provider';
import { Wrapper, HorizontalLine, ViewCartButton } from './styles';
import MinicartBigRow from './MinicartBigRow';

const MinicartBig = ({
  cart,
  closeBigCart,
  gotoCart,
  removeFromCart,
}: {
  cart: Cart,
  closeBigCart: Function,
  gotoCart: Function,
  removeFromCart: Function,
}) => {
  const keys = Object.keys(cart);
  const listOfCreeptoma = keys.reduce((acc, cur) => {
    const item = {
      id: acc.reduce((maxId, c) => Math.max(maxId, c.id || 0), -1) + 1,
      name: cur,
      total: cart[cur]
        .reduce((total, current) => total + current, 0)
        .toFixed(4),
      length: cart[cur].length,
    };
    return acc.concat(item);
  }, []);

  const numOfCreeptoma = listOfCreeptoma.reduce((a, b) => a + b.length, 0);

  let totalPrice = +listOfCreeptoma
    .reduce((acc, cur) => +acc + +cur.total, 0.0)
    .toFixed(4);

  if (totalPrice.toString().length <= 6) {
    totalPrice = totalPrice.toString().padEnd(6, '0');
  }

  return (
    <Fixed
      zIndex={1000}
      right={['20px', , , , , '5vw', '10vw', '15vw']}
      top={['20px', 40, 90]}
    >
      <Wrapper w={[280]}>
        <CloseButton onclick={closeBigCart} />
        <Flex flexDirection="column" alignItems="center">
          <Box mt={18} w={1}>
            <Flex justifyContent="space-around">
              <Text textAlign="left" fontWeight="normal">
                Creeptoma
              </Text>
              <Text textAlign="left" fontWeight="normal">
                Qty
              </Text>
              <Text textAlign="right" fontWeight="normal">
                Price (ETH)
              </Text>
            </Flex>
            <HorizontalLine />
            <Flex flexDirection="column">
              {listOfCreeptoma.map(({ id, name, total, length }) => (
                <MinicartBigRow
                  removeFromCart={removeFromCart}
                  key={id}
                  name={name}
                  total={total}
                  length={length}
                />
              ))}
            </Flex>
            <HorizontalLine />
            <Flex>
              <Box w={100}>
                <Text ml={3} fontWeight="normal">
                  Total ({`${numOfCreeptoma}`})
                </Text>
              </Box>

              <Text ml={89} fontWeight="bold">
                {`${totalPrice}`}
              </Text>
            </Flex>
          </Box>
          <Box mb={20} mt={[3, 4]}>
            <ViewCartButton onClick={gotoCart} px={[4, 5]} py={[11]}>
              <Text color="#fff" f={2}>
                VIEW CART
              </Text>
            </ViewCartButton>
          </Box>
        </Flex>
      </Wrapper>
    </Fixed>
  );
};

export default MinicartBig;
