import { discordIcon, telegramIcon } from 'img';

export const socialMediaInfo = [
  {
    id: 1,
    name: 'Telegram',
    src: telegramIcon,
    socialColor: 'telegram',
    hoverColor: '#234150',
  },
  {
    id: 2,
    name: 'Discord',
    src: discordIcon,
    socialColor: 'discord',
    link: 'https://discordapp.com/invite/EdjasxA',
    hoverColor: '#2c3863',
  },
];

export type SocialMediaInfoItem = {
  id: number,
  name: string,
  src: ImageData,
  socialColor: string,
};
