// @flow
/* eslint no-sparse-arrays: 0 */
import * as React from 'react';
import { withRouter } from 'react-router-dom';

import type { Location, RouterHistory } from 'react-router-dom';
import { Cart } from 'provider';

import withConsumer from 'helpers/withConsumer';
import compose from 'helpers/compose';
import MinicartSmall from './MinicartSmall';
import MinicartBig from './MinicartBig';

const calculateTotalCreeptoma = cart => {
  const keys = Object.keys(cart);
  const totalCreeptoma = keys.reduce((acc, cur) => acc + cart[cur].length, 0);
  return totalCreeptoma;
};

class MiniCart extends React.PureComponent<
  {
    isMetaMaskExisted: boolean,
    location: Location,
    history: RouterHistory,
    cart: Cart,
    totalPrice: number,
    removeFromCart: Function,
  },
  { isOpen: boolean }
> {
  state = {
    isOpen: false,
  };

  static getDerivedStateFromProps(props, state) {
    const { cart } = props;
    const totalCreeptoma = calculateTotalCreeptoma(cart);
    return {
      isOpen: state.isOpen && Boolean(totalCreeptoma),
    };
  }

  handleCloseBigCart = () => {
    this.setState({
      isOpen: false,
    });
  };

  handleMinicartClick = () => {
    this.setState({
      isOpen: true,
    });
  };

  handleViewCart = () => {
    const { isMetaMaskExisted } = this.props;

    if (isMetaMaskExisted) {
      this.props.history.push('cart');
    } else {
      this.setState({
        isOpen: false,
      });
      this.props.history.push('nometamask');
    }
  };
  render() {
    const { cart, location, totalPrice, removeFromCart } = this.props;
    const totalCreeptoma = calculateTotalCreeptoma(cart);
    const render =
      ['/cart', '/nometamask'].every(
        routeName => routeName !== location.pathname
      ) && totalCreeptoma;
    return render ? (
      <React.Fragment>
        {this.state.isOpen ? (
          <MinicartBig
            closeBigCart={this.handleCloseBigCart}
            cart={cart}
            gotoCart={this.handleViewCart}
            removeFromCart={removeFromCart}
          />
        ) : (
          <MinicartSmall
            onclick={this.handleMinicartClick}
            totalCreeptoma={totalCreeptoma}
            totalPrice={totalPrice}
          />
        )}
      </React.Fragment>
    ) : null;
  }
}

export default compose(
  withConsumer,
  withRouter
)(MiniCart);
