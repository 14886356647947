// @flow
import * as React from 'react';
import Media from 'react-media';
import Loadable from 'react-loadable';
import { Route, Switch, Redirect } from 'react-router-dom';

import Background from 'layouts/Background';
import Loading from 'common/Loading';
import { Wrapper, Footer } from 'common/Position';

import PageFooter from 'layouts/Footer';
import Maintenance from 'common/Maintenance';
import Navbar from 'layouts/Navbar';
import MiniCart from 'layouts/MiniCart';
import SocialMedia from 'layouts/SocialMedia';

const Articles = Loadable({
  loader: () => import('pages/Articles'),
  loading: Loading,
});

const Cart = Loadable({
  loader: () => import('pages/Cart' /* webpackChunkName: "cart" */),
  loading: Loading,
});

const Characters = Loadable({
  loader: () => import('pages/Characters' /* webpackChunkName: "characters" */),
  loading: Loading,
});

const Creeptoma = Loadable({
  loader: () => import('pages/Creeptoma' /* webpackChunkName: "creeptoma" */),
  loading: Loading,
});

const Gameplay = Loadable({
  loader: () => import('pages/Gameplay' /* webpackChunkName: "gameplay" */),
  loading: Loading,
});

const Home = Loadable({
  loader: () => import('pages/Home' /* webpackChunkName: "home" */),
  loading: Loading,
});

const Pricing = Loadable({
  loader: () => import('pages/Pricing' /* webpackChunkName: "pricing" */),
  loading: Loading,
});

const NotFound = Loadable({
  loader: () => import('pages/Notfound' /* webpackChunkName: "404" */),
  loading: Loading,
});

const Playground = Loadable({
  loader: () => import('pages/Playground' /* webpackChunkName: "404" */),
  loading: Loading,
});

const NoMetamask = Loadable({
  loader: () =>
    import('./pages/NoMetamask' /* webpackChunkName: "notsignedin" */),
  loading: Loading,
});

const Island = Loadable({
  loader: () => import('pages/Island' /* webpackChunkName: "island" */),
  loading: Loading,
});

type RoutesProps = {
  maintenanceMode: boolean,
};

type RoutesState = {
  auth: boolean,
};

const AuthRender = () => (
  <Background>
    <Navbar />
    <Wrapper>
      <MiniCart />
      <SocialMedia />

      <Media query="(min-width: 48em)">
        {isMatch =>
          isMatch ? <div id="blurryscroll" aria-hidden="true" /> : null
        }
      </Media>

      <main role="main">
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/home" render={() => <Redirect to="/" />} />
          <Route path="/island" component={Island} />
          <Route path="/characters" component={Characters} />
          <Route path="/gameplay" component={Gameplay} />
          <Route path="/creeptoma" component={Creeptoma} />
          <Route path="/pricing" component={Pricing} />
          <Route path="/articles" component={Articles} />
          <Route path="/cart" component={Cart} />
          <Route path="/nometamask" component={NoMetamask} />
          {/* {process.env.NODE_ENV === 'production' ? null : ( */}
          <Route path="/playground" component={Playground} />
          {/* )} */}
          <Route component={NotFound} />
        </Switch>
      </main>
      <Footer>
        <Route component={PageFooter} />
      </Footer>
    </Wrapper>
  </Background>
);

class Routes extends React.Component<RoutesProps, RoutesState> {
  render() {
    if (this.props.maintenanceMode) {
      return <Maintenance />;
    }

    return <AuthRender />
  }
}

export default Routes;
