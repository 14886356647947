/* eslint no-sparse-arrays: 0 */
import * as React from 'react';
import styled from 'styled-components';
import { A } from '../A';

const socialIconBackgroundColorsMap = {
  telegram: '#0088cc',
  discord: '#7289da',
};

export const SocialIconWrapper = styled.div`
  background-color: ${props =>
    props.color
      ? socialIconBackgroundColorsMap[props.color]
      : props.theme.colors.footerSocialIconBackgroundColor};
  height: 40px;
  width: 40px;
  border-radius: 50%;
  cursor: pointer;

  margin-left: 8px;
  &:hover {
    background-color: ${props => props.hovercolor};
  }
`;

const SocialIconFlex = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
`;

export const SocialIcon = ({
  name,
  src,
  socialColor,
  hoverColor,
  link,
}: {
  name: string,
  src: ImageData,
  socialColor: string,
  hoverColor: string,
  link: string,
}) => (
  <SocialIconWrapper color={socialColor} hovercolor={hoverColor}>
    <A linkTo={link}>
      <SocialIconFlex>
        <img alt={`${name} social icon`} src={src} />
      </SocialIconFlex>
    </A>
  </SocialIconWrapper>
);
